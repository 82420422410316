import {motion} from "framer-motion";
import {PillarColor} from "../shared";
import {useCallback, useMemo} from "react";

export default function Orb({pillarColor}: { pillarColor: PillarColor }) {
    const colors = useMemo(() => {
        const c = [...pillarColor.gradient];
        return c.reverse()
    }, [pillarColor]);

    const size = useCallback((i) => {
        return {
            0: 2.5,
            1: 1.6,
            2: .65,
        }[i]
    }, []);

    const rotateEnd = useMemo(() => {
        return Math.random() * 100 - 50;
    }, [pillarColor]);

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    filter: 'blur(140px)',
                }}
            >
                {
                    colors.slice(0, 4).map((color, i) => (
                        <motion.div
                            key={i}
                            style={{
                                color: color,
                                position: 'absolute',
                                opacity: 1,
                            }}
                            initial={{
                                width: `${size(i) * 70}%`,
                                height: `${size(i) * 70}%`,
                                left: i === 0 ? '-35%' : `${size(i) * 7}%`,
                                top: i === 0 ? '-50%' : `${size(i) * -15}%`,
                                // rotate: 0,
                            }}
                            animate={{
                                width: `${size(i) * 170}%`,
                                height: `${size(i) * 170}%`,
                                left: i === 0 ? '-35%' : `${size(i) * -35}%`,
                                top: i === 2 ? '40%' : i === 0 ? '-120%' : `${size(i) * -35}%`,
                            }}
                            transition={{duration: 8, ease: 'linear'}}
                        >
                            <Shape shouldAnimate={![0, 2].includes(i)}/>
                        </motion.div>
                    ))
                }
            </div>
            <svg id="turbulence">
                <filter id="noise">
                    <feTurbulence
                        type='fractalNoise'
                        baseFrequency='.4'
                        numOctaves='2'
                        stitchTiles='stitch'
                    >
                    </feTurbulence>
                </filter>
            </svg>
            <motion.div
                style={{
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    filter: `url(#noise)`,
                    opacity: .4,
                }}
            />
        </>
    )
}

function Shape({shouldAnimate = false}: { shouldAnimate?: boolean }) {
    const rotate = useMemo(() => {
        return Math.random() * 400 - 200;
    }, []);

    const y = useMemo(() => {
        return Math.random() * 800 - 400;
    }, []);

    return (
        <motion.svg
            style={{
                width: shouldAnimate ? '250%' : '100%',
                margin: shouldAnimate ? '-0% -75%' : 0,
            }}
            viewBox={shouldAnimate ? "0 0 1300 1276" : "0 0 948 1276"}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <motion.ellipse
                initial={{
                    y: shouldAnimate ? -400 : 0,
                    x: 0,
                    rotate: 0,
                    scale: shouldAnimate ? .5 : 1
                }}
                animate={{
                    y: shouldAnimate ? [0, y, 0, 100] : 0,
                    x: shouldAnimate ? -200 : 0,
                    rotate: shouldAnimate ? [rotate, 0, rotate] : 0,
                    scale: shouldAnimate ? [.4, .9, 1, .7] : 1
                }}
                transition={{
                    duration: 10
                }}
                cx="474"
                cy="764"
                rx="474"
                ry="512"
                fill="currentColor"
            />
            <motion.ellipse
                initial={{
                    x: 0,
                    rotate: 0,
                    scale: 1
                }}
                animate={{
                    x: shouldAnimate ? 100 : 0,
                    rotate: shouldAnimate ? -100 : 0,
                    scale: shouldAnimate ? [.7, .3, .8] : 0,
                }}
                transition={{
                    duration: 4,
                    repeat: Infinity,
                    repeatType: 'reverse',
                }}
                cx="537.5"
                cy="336"
                rx="310.5"
                ry="336"
                fill="currentColor"
            />
        </motion.svg>

    )
}