import {MediaType} from "../../jordan/shared";
import {useMemo} from "react";
import Typography from "../../jordan/components/Typography";

export default function BottomSection({title, superTitle, media, size = 200}: {
    title: string,
    superTitle: string,
    media: Array<MediaType>,
    size?: number
}) {
    const multiplier = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const size = urlParams.get('qr-size');

        if (!size) {
            return 1
        }

        return Number(size) ?? 1;
    }, []);

    return (
        <div style={{position: 'absolute', bottom: '50px', left: '50px', zIndex: 99}}>
            <div style={{display: 'flex', gap: 20, alignItems: 'flex-end'}}>
                <img
                    src={media?.[0]?.url}
                    style={{
                        width: size * multiplier,
                        height: size * multiplier,
                    }}
                />
                <div>
                    <Typography
                        variant={'pp-ultralight-italic'}
                        color={'currentColor'}
                        size={37}
                    >
                        {superTitle}
                    </Typography>
                    <Typography
                        variant={'neue'}
                        color={'currentColor'}
                        size={80}
                        style={{
                            fontWeight: 900,
                            letterSpacing: '-0.04em',
                        }}
                    >
                        {title}
                    </Typography>
                </div>
            </div>
        </div>
    )
}