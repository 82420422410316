import Transition from "../../jordan/components/Transition";
import {Chapter, transitions} from "../shared";
import Typography from "../../jordan/components/Typography";
import Title from "../components/title";
import {motion} from "framer-motion";
import ChapterContainer from "../components/chapter-container";
import {DEFAULT_TRANSITION} from "../../rise/constants/transition";
import {useEffect, useState} from "react";
import Media from "../../jordan/components/Media";
import QrCode from "../components/qr-code";
import BottomSection from "../components/bottom-section";

const transition = transitions.slideIn;

export default function Event({chapter}: { chapter: Chapter }) {
    const [playVideo, setPlayVideo] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPlayVideo(true)
        }, 6_000);
    }, []);

    return (
        <ChapterContainer chapter={chapter}>
            {
                chapter.media?.length > 0 && (
                    <motion.div
                        style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', zIndex: 0}}
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: playVideo ? .8 : 0,
                        }}
                    >
                        <Media
                            cover={'fill'}
                            preventExit={true}
                            media={chapter?.media}
                            width={'100%'}
                            height={'100%'}
                            effect={'zoom-out-center'}
                        />
                    </motion.div>
                )
            }

            <motion.div
                style={{top: 362, position: 'absolute', left: 50}}
                {...transition}
            >
                {
                    chapter?.title && (
                        <div style={{maxWidth: '60%'}}>
                            <Title>
                                {chapter.title}
                            </Title>
                        </div>

                    )
                }

                <Typography color={'currentColor'} size={60} style={{marginTop: 66, textTransform: 'lowercase'}} variant={'pp-ultralight-italic'}>
                    {chapter?.subtitle}
                </Typography>
            </motion.div>

            <motion.div
                style={{top: 1260, position: 'absolute', right: 50, width: '50%'}}
                {...transition}
                transition={{
                    ...DEFAULT_TRANSITION,
                    delay: 1,
                }}
            >
                <Typography
                    variant={'brut'}
                    style={{textTransform: 'uppercase'}}
                    size={53}
                    color={'currentColor'}
                >
                    {chapter.bottom_section?.title ? '' : chapter?.pillar}
                </Typography>

                <div style={{
                    // display: 'flex',
                    textTransform: 'uppercase',
                    // justifyContent: 'space-between',
                    marginRight: 20,
                    position: 'relative',
                }}>
                    {
                        chapter?.details?.map((detail, i) => (
                            <Typography
                                color={'currentColor'}
                                size={53}
                                style={{
                                    marginTop: 20,
                                    // width: '50%'
                            }}
                                variant={'neue'}
                            >
                                {detail}
                            </Typography>
                        ))
                    }
                </div>

            </motion.div>

            {
                chapter.bottom_section ? (
                    <BottomSection
                        media={chapter.bottom_section.media}
                        title={chapter.bottom_section.title}
                        superTitle={chapter.bottom_section.super_title}
                    />
                ) : (
                    <QrCode
                        cta={chapter?.event_type === 'local_event' ? chapter?.cta : null}
                        hideSwoosh={chapter?.event_type === 'local_event'}
                    />
                )
            }


        </ChapterContainer>
    )
}