import {ReactNode} from "react";
import Typography from "../../jordan/components/Typography";

export default function Title({children}: {children: ReactNode}) {
    return (
        <Typography
            variant={'brut'}
            size={150}
            color={'currentColor'}
            style={{
                textTransform: 'lowercase',
                textWrap: 'balance',
                lineHeight: '0.91em',
            }}
        >
            {children}
        </Typography>
    )
}