import {Chapter} from "../shared";
import Transition from "../../jordan/components/Transition";
import {ReactNode} from "react";
import Orb from "./orb";
import Progress from "./progress";
import QrCode from "./qr-code";

export default function ChapterContainer({chapter, children}: { chapter: Chapter, children: ReactNode }) {
    return (
        <Transition variant={'fade'} style={{position: 'absolute', color: chapter?.pillar_color?.color}}>
            <Orb pillarColor={chapter.pillar_color}/>
            <Progress chapter={chapter}/>
            {children}
        </Transition>
    )
}