import React, {useMemo} from 'react';
import Transition from "../components/Transition";
import {MediaType} from "../shared";
import {default as MediaPlayer} from '../components/Media';
import VerticalTicker, {TickerType} from "../components/VerticalTicker";
import CenterCourtVerticalTicker from "../components/CenterCourtVerticalTicker";
import WidescreenQrSection from "../components/WidescreenQrSection";
import {QrCodeInterface} from "../components/QrSection";
import {JordanConfig} from "../components/ChapterRunner";

interface MediaProps {
    media: Array<MediaType>;
    ticker?: TickerType;
    custom_ticker?: MediaType;
    config?: any;
    qr_code?: QrCodeInterface;
}

export function calculateDuration({media, ticker}: MediaProps, config: JordanConfig) {
    const defaultDuration = config?.meta?.gradient ? 5 : 0;

    const duration = media?.filter(i => i)?.reduce((acc, curr) => {
        const duration = curr?.force_duration === 'full' ? curr.duration : Number(curr.force_duration);
        return acc + duration;
    }, 0) ?? defaultDuration

    return duration * 1_000;
}

export default function Media({media, ticker, custom_ticker, config, qr_code}: MediaProps) {
    const isRectangular = useMemo(() => {
        return config?.meta?.isRectangular;
    }, [config]);

    return (
        <Transition variant={'wipe-x'} cover>
            <MediaPlayer
                preventInitial
                media={media}
                width={'100%'}
                height={'100%'}
                useInternalTiming
                preventExit
            />

            {
                (ticker && !isRectangular) && (
                    <div style={{
                        position: 'absolute',
                        right: '0',
                        height: '100%',
                        width: 100,
                        zIndex: 8,
                        top: 0,
                        display: 'flex'
                    }}>
                        <VerticalTicker
                            asset={custom_ticker}
                            theme={'dark'}
                            type={ticker}
                        />
                    </div>
                )
            }

            {
                ticker && isRectangular && (
                    <CenterCourtVerticalTicker verticalTicker={ticker} customTicker={custom_ticker} />
                )
            }

            <WidescreenQrSection qr_code={qr_code} />
        </Transition>
    )
}