import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {Perf} from 'r3f-perf';
import './style.scss';
import {Canvas} from '@react-three/fiber';
import {VideoWall} from './VideoWall';
import {VideoData} from './type';
import {canPlayAudio} from "./helpers";

export default function CoreVideo({data}: { data: VideoData }) {
    const [showPerf] = useState(false);
    const perf = useMemo(() => <Perf/>, []);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const origin = urlParams.get('origin');
    const [assetID, setAssetId] = useState(0);
    const asset = data?.assets?.[assetID];
    const [muted, setMuted] = useState(null);

    useEffect(() => {
        canPlayAudio().then(() => {
            console.log('can play audio');
            const m = urlParams.get('muted');
            // should not play if set to false
            setMuted(m === 'false' ? false : !!m);
        }).catch((e) => {
            console.log('cannot play audio', e);
            setMuted(true);
        });
    }, []);

    console.log('muted', muted);

    let layout: Partial<VideoData> = {
        height: asset?.height ?? 960,
        width: asset?.width ?? 1280,
        canvas_width: asset?.width ?? 1280,
        rows: 1,
    };

    if (origin === 'pixel-map') {
        const height = Number(urlParams.get('height') ?? 48);
        const width = Number(urlParams.get('width') ?? 3_840);

        layout.height = height;
        layout.width = window.innerWidth;
        layout.canvas_width = width;
    }

    const wrap = layout.canvas_width > layout.width;
    layout.rows = Math.ceil((layout.canvas_width || layout.width) / layout.width);

    const onVideoEnded = useCallback(() => {
        if (data?.assets?.length > 1) {
            setAssetId(assetNumber => {
                let newAssetNumber = assetNumber;
                newAssetNumber++;
                if (newAssetNumber > data?.assets?.length - 1) {
                    newAssetNumber = 0;
                }
                return newAssetNumber;
            });
        }
    }, [data?.assets?.length, setAssetId]);

    const video = useMemo(() => {
        if (muted === null) {
            return null;
        }

        return (
            <Canvas
                gl={{antialias: true}}
                flat={true}
                dpr={[1, 2]}
                orthographic
                linear={false}
                camera={{
                    zoom: 1,
                    position: [0, 0, 200],
                    near: 0.1,
                    far: 10000,
                }}
                style={{
                    background: 'black',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: layout.width,
                    height: wrap ? layout.height * layout.rows : layout.height,
                }}
            >
                {asset && (
                    <VideoWall
                        key="video-wall"
                        layout={layout}
                        url={asset.url}
                        loop={data?.assets?.length === 1}
                        onVideoEnded={onVideoEnded}
                        muted={muted}
                    />
                )}
                {showPerf && perf}
            </Canvas>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset, muted]);

    return video;
}
