import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

gsap.registerPlugin(ScrambleTextPlugin);

export default function presetTL (switches=[]) {
  const tl = this.tl;
  const { uniforms } = this.visualLayer;
  const vf = this.viewFinderMarks;

  const cornerText = this.verticalTextBottom.querySelector('h2');
  // this.verticalTextTop.style.minHeight = `${this.datas.assets[0]?.meta?.toUpperCase().length * 15}px`

  tl.add(gsap.set(uniforms.pixa, { value: 1 }), 0);
  // tl.add(gsap.set(uniforms.img1Zoom, { value: 0.45 }), 0);
  tl.add(gsap.set(vf, { scale: 2 }), 0);
  tl.add(gsap.set(this.getAllInOverlay('.swatch-item'), { value: 0 }), 0);
  tl.add(gsap.set(vf, { opacity: 0 }), 0);

  tl.add(gsap.to(vf, { scale: 1.0, duration: 0.5 }), 0.1);

  tl.add(gsap.to(this.getAllInOverlay('.swatch-item'), { opacity: 1, duration: 1, ease: 'expo.in' }), 0.3);
  tl.add(gsap.to(this.verticalTextTop.querySelector('h2'), {
    duration: 1,
    scrambleText: {
      text: this.datas.assets[0]?.meta?.toUpperCase(),
      chars: '             NIKE             ',
    }
  }), 0.1);
  textSwitch(0.3, this.datas.metaText?.EN?.toUpperCase(), 1);

  tl.add(gsap.to(vf, { opacity: 1, duration: 0.1, ease: 'steps(1)' }), 0.1);
  tl.add(gsap.to(vf, { opacity: 0, duration: 0.1, ease: 'steps(1)' }), 0.2);
  tl.add(gsap.to(vf, { opacity: 1, duration: 0.1, ease: 'steps(1)' }), 0.3);

  function textSwitch (time, text) {

    tl.add(gsap.to(cornerText, {
      duration: 1,
      scrambleText: {
        text:  text,
        chars: '             NIKE             ',
      }
    }), time + 0.4);
  }

  switches.forEach(({ time, text, onPixelEffect }) => {
    tl.add(onPixelEffect, time)
    text && textSwitch(time, text.toUpperCase());
  });

  tl.call(() => {}, null, 8);
}
