import React, {useCallback, useMemo} from 'react';
import {MediaType, qrUrlTrack, stringFallback, ThemeType} from "../shared";
import Typography from "./Typography";
import {qr} from "@tensorflow/tfjs-core/dist/ops/linalg/qr";

interface QrSectionProps {
    theme: ThemeType;
    qrCode: QrCodeInterface;
    preventDefaults?: boolean;
}

export interface QrCodeInterface {
    label?: string;
    disable?: boolean;
    assets: Array<MediaType>;
    hide_images?: boolean;
}

const DEFAULT_ASSETS = [
    {
        "url": "https://static.nike.com.cn/sportpulse/image/upload/c_fill,h_200,w_200/b_transparent,c_mpad,h_201,w_201/v1666708293/nikehyperlive-staging/1666708293796.png?_a=ATO2BAA0",
        "width": 1000,
        "format": "png",
        "height": 1000,
        "original_url": "https://static.nike.com.cn/sportpulse/image/upload/v1666708293/nikehyperlive-staging/1666708293796.png",
        "resource_type": "image",
        "destination_url": "https://nike.com"
    },
    {
        'url': 'https://res.cloudinary.com/jbboerner/image/upload/v1670527810/nikehyperlive-staging/1670527810817.png?_a=ATO2BAA0',
        'etag': '78cd24d99d74f345e4baf320db9e049c',
        'width': 180,
        'format': 'png',
        'height': 180,
        'original_url': 'https://res.cloudinary.com/jbboerner/image/upload/v1670527810/nikehyperlive-staging/1670527810817.png',
        'resource_type': 'image',
        'name': 'Nike Swoosh Logo',
    }
    // {
    //     "url": "https://static.nike.com.cn/sportpulse/image/upload/c_fill,h_200,w_200/b_transparent,c_mpad,h_201,w_201/v1666708309/nikehyperlive-staging/1666708309038.svg?_a=ATO2BAA0",
    //     "width": 86,
    //     "format": "svg",
    //     "height": 30,
    //     "original_url": "https://static.nike.com.cn/sportpulse/image/upload/v1666708309/nikehyperlive-staging/1666708309038.svg",
    //     "resource_type": "image"
    // }
]

export default function QrSection({qrCode, theme, preventDefaults = false}: QrSectionProps) {
    if (Array.isArray(qrCode)) {
        return null;
    }

    const {assets, disable = false, label, hide_images = false} = qrCode ?? {};
    const color = theme === 'dark' ? 'white' : 'black';

    const scanLabel = useMemo(() => {
        if (preventDefaults) {
            return label ?? '';
        }
        return stringFallback(label, 'SCAN TO LEARN')
    }, [label, preventDefaults]);
    const getUrl = useCallback((media: MediaType) => {
        if (!media?.destination_url) {
            return media?.original_url ?? media.url
        }

        return qrUrlTrack(media.destination_url);
    }, [assets]);

    // fallback to default assets when the uploaded assets are not set.
    const minAssets = useMemo(() => {
        return [...new Array(2)].map((asset, i) => {
            if (preventDefaults) {
                return assets?.[i];
            }
            return assets?.[i]?.url ? assets?.[i] : DEFAULT_ASSETS?.[i];
        }).filter(a => a);
    }, [assets]);

    if (disable || (preventDefaults && !qrCode?.assets?.length)) {
        return null;
    }

    return (
        <div>
            <Typography size={18} variant={'jordan'} color={color} style={{lineHeight: '130%', marginLeft: 5}}>
                {scanLabel}
            </Typography>
            {
                hide_images === true ? null : (
                    <div style={{display: 'flex', gap: '10px', marginTop: '10px'}}>
                        {
                            minAssets?.map((media: MediaType, i) => (
                                <div
                                    key={`graphic_${i}`}
                                    style={{
                                        height: 72,
                                        width: 72,
                                    }}
                                >
                                    <img
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '8px',
                                            background: 'white',
                                        }}
                                        src={getUrl(media)}
                                    />
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    )
}