import React from 'react';
import Text from './text';
import {SIZES} from "./constants";

const FastLaneViewer = ({ runner, lanes, textJson, circleGap, svgCircle, persistentTitle, qrWidth, size= SIZES.WIDE, fontSizeMultiplier }) => {
	let index = 0;

	return (
		<>
			{lanes
				.map((lane, i) => {
					if (lane.isFiller || !textJson[index]) return null;
					const num = lane.getNumContainers();
					const JSX = new Array(num).fill(0).map((_, j) => {
						return (
								<Text
									key={`text_${i}_${j}`}
									lane={lane}
									runner={runner}
									svgCircle={svgCircle}
									persistentTitle={persistentTitle}
									wrap={j === 1}
									circleGap={circleGap}
									json={textJson[index]}
									size={size}
									qrWidth={qrWidth}
									fontSizeMultiplier={fontSizeMultiplier}
								/>
							
						);
					});
					index++;
					return JSX;
				})
				.flat()}
		</>
	);
};

export default FastLaneViewer;
