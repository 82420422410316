import {motion} from 'framer-motion';
import {useEffect, useMemo, useState} from "react";
import {keyframe} from "../../../jordan/shared";
import {configOverride} from "./utils";
import {NIKE_EASE} from "../ntc/constants";

export default function Circle({index, marginRight, height, renderLineRight}) {
  const variants = [
    {
      pathLength: 0,
      rotate: 0,
    },
    {
      pathLength: .75,
      rotate: 0
    },
    {
      pathLength: 0,
      rotate: 270,
    },
  ];

  const [showLine, setShowLine] = useState(false);
  const [active, setActive] = useState(0);

  const nextFrame = () => {
    setActive((a) => a + 1);
  }

  const reset = (scene) => {
    setActive(0);
    if (scene === 2) {
      return;
    }
    setScene(i => i + 1);
  }

  const [scene, setScene] = useState(0);

  useEffect(() => {
    const {trigger, clear} = keyframe(1.5);

    trigger
    (0, nextFrame) // double y action split
      (6, nextFrame) // product
      (1, () => reset(scene)) // product


    return () => {
      clear();
    }
  }, [scene]);

  useEffect(() => {
    const {trigger: lineTrigger, clear: lineClear} = keyframe(0);

    lineTrigger
    (0, () => setShowLine(true))
    (35, () => setShowLine(false))

    return lineClear
  }, []);

  const overrides = configOverride();

  const color = useMemo(() => {
    const c = overrides.circleColor;

    if (!c) {
      return '#fff';
    }

    if (c?.length === 6) {
      return `#${c}`;
    }

    return `rgba(${overrides.circleColor}, ${overrides.circleColor}, ${overrides.circleColor}, 1)`;
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        height: `${height}px`,
        display: 'inline-block',
        marginRight: marginRight + 1 - (height * .1005),
      }}
    >
      <motion.svg
        width={`${height + 6}px`}
        height={`${height + 6}px`}
        viewBox="0 0 600 600"
        initial="hidden"
        animate="visible"
        style={{
          position: 'relative',
          rotate: 180,
          scaleX: -1,
          transformOrigin: 'center',
        }}
      >

        <motion.line
          x1="301"
          x2="301"
          y1="0"
          y2="600"
          stroke={color}
          animate={{
            pathLength: showLine ? 1 : 0,
            strokeWidth: overrides.circleThickness ?? 1,
          }}
          transition={{
            duration: 1,
            delay: (index + 1) * .1,
            ease: NIKE_EASE,
          }}
        />

        <motion.line
          x1="28"
          x2="28"
          y1="0"
          y2="600"
          stroke={color}
          animate={{
            pathLength: showLine ? 1 : 0,
            strokeWidth: overrides.circleThickness ?? 1,
          }}
          transition={{
            delay: index * .1,
            duration: 1,
            ease: NIKE_EASE,
          }}
        />

        {
          renderLineRight && (
            <motion.line
              x1="572"
              x2="572"
              y1="0"
              y2="600"
              stroke={color}
              animate={{
                pathLength: showLine ? 1 : 0,
                strokeWidth: overrides.circleThickness ?? 1,
              }}
              transition={{
                delay: (index + 1) * .1,
                duration: 1,
                ease: NIKE_EASE,
              }}
            />
          )
        }

        <motion.circle
          cx="300"
          cy="308"
          r={300 - (.1 * 300)}
          stroke={color}
          fill={'transparent'}
          style={{
            strokeWidth: overrides.circleThickness ?? 1,
          }}
          animate={variants?.[active]}
          strokeDasharray={290}
          transition={{
            duration: 1,
            ease: NIKE_EASE,
          }}
        />
      </motion.svg>
    </div>
  )
}