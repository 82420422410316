if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import {
  Uniform,
  Vector2,
  Color
} from 'three';
import gsap from 'gsap';
import { ScrambleTextPlugin } from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';

import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './pdp-head-toe.frag';
import presetTL from './pdp-head-toe-timeline';

import './pdp-head-toe.scss'

class PDPHeadToe extends UniversalLiveTemplate {
  constructor (name, datas, onComplete) {
    super(name);
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.setCustomSize(Math.floor(width), Math.floor(height));

    this.datas = datas;
    this.overlayWrapper = this.addNode({type: 'overlayWrapper', 
      freeflow:true
    });

    this.viewFinderMarks = this.addNode({type: 'viewFinderMarks', 
      freeflow:true,
      content: { stroke: '2px', size: 80 }
    });

    this.verticalTextTop = this.addNode({type: 'verticalText', 
      parent: true, 
      className:"top-right",
      content: { text: '' }
    });

    this.verticalTextBottom = this.addNode({type: 'verticalText', 
      parent: true, 
      className:"bottom-right",
      content: { text: ''}
    });

    if (this.datas.assets[0]?.swatches?.length) {
      const offsets = [0, 28.5, 0, 0]; // quick and dirty
    }

    const swatchSizes = {
      SMALL: '20',
      MEDIUM: '30',
      LARGE: '50',
    }
    if (this.datas.colors || this.datas.assets[0]?.swatches) {
      const offsets = [0, 0, 0, 8, 0, 0, 6, 0, 0, 0, 5.5, 0]; // quick and dirty

      const swatches = new Array(6).fill({}).map((e, i) => {
        return {
          color: this.datas.assets[0].swatches[i % this.datas.assets[0].swatches.length],
          offset: offsets[i] || 0
        };
      });
      this.addNode({type: 'swatchList', parent: true,
      content: { swatches, size: swatchSizes[window.SIZE_ID] }})
    }

    if (this.datas.cta) {
      this.setCenterRightSlot({
        type: 'placeText',
        content: {
          text: '',
          textAlign: 'right',
          width: `${this.relWidth(50)}px`,
          class: 'head-toe-cta',
          right: -12,
          bottom: `calc(50% + ${this.relHeight(55)}px)`
        }
      });
      this.cta = document.querySelector(`.live-app.${this.name} .head-toe-cta`);
      this.cta.style.font = `500 ${this.relHeight(12)}px "helvetica-neue-medium"`;
    }
    if (this.datas.extra?.length) {
      this.setCenterRightSlot({
        type: 'placeImg',
        content: {
          src: this.datas.extra[0]?.url ?? this.datas.extra[1]?.url,
          width: `${this.relWidth(50)}px`,
          class: 'head-toe-picto',
          right: 0,
          bottom: `calc(50% - ${this.relHeight(28)}px)`
        }
      });
    }

    this.mediasCount = this.datas.assets.filter(a => a?.url).length;

    this.registerTextureURL({
      src: this.datas.assets[0].url,
      key: 'img1',
      pos: { x: (this.width / 2), y: (this.height / 2) },
      dim: { x: this.width, y: this.height },
      offset: { x: 0, y: 0 },
      pixel: 1,
      zoom: 1,
      forceJPG: true,
    });

    const center = new Vector2(
      (this.width / 2),
      (this.height / 2)
    );

    const uniforms = {
      time: new Uniform(0),
      res: new Uniform(center),
      pixa: new Uniform(1),
      pixr: new Uniform(window.devicePixelRatio),
      stroke: new Uniform(1),
      zoom: new Uniform(0.25),
      mosaicAmount: new Uniform(0),
      mosaicColor: new Uniform(1),
      flip: new Uniform(1),
    }

    this.createVisualLayer({ frag, vert }, uniforms);
    this.setupTimeline(onComplete);
  }

  populateTimeline () {
    if (this.cta) {
      this.tl.add(gsap.to(this.cta, {
        duration: 0.5,
        scrambleText: {
          text: this.datas.cta?.EN?.toUpperCase(),
          chars: '             NIKE             ',
        }
      }), this.datas.extra?.[0] ? 4 : 0.5);
    }

    const switches = [];
    for (let i = 1; i < this.mediasCount; i++) {
      switches.push({
        time: 2 * i,
        text: this.datas.metaText?.EN,
        onPixelEffect: () => {
          this.visualLayer.createTexture({
            src: this.datas.assets[i].url,
            key: 'img1',
            forceJPG: true,
          }, false);
          if (i > 1) {
            if (this.datas.extra?.[0]?.url && this.datas.extra?.[1]?.url) {
              const imgSlot = document.querySelector(`.live-app.${this.name} .head-toe-picto img`);
              imgSlot.src = this.datas.extra[1]?.url;
              imgSlot.classList.add('is-qr');
            }
          }
        }
      });
    }

    presetTL.bind(this)(switches);
  }

  preStart () {
    this.visualLayer.createTexture({
      src: this.datas.assets[0].url,
      key: 'img1',
      forceJPG: true,
    }, false);
    this.setGLUni('flip', 1, true);
    const imgSlot = document.querySelector(`.live-app.${this.name} .head-toe-picto img`);
    if (this.datas.extra?.[0]?.url) {
      imgSlot.src = this.datas.extra[0]?.url;
      imgSlot.classList.remove('is-qr');
    } if (!this.datas.extra?.[0] && this.datas.extra?.[1]?.url) {
      imgSlot.classList.add('is-qr');
    }

    this.wrapper.classList.add('active');
  }
}
export default PDPHeadToe;
