import {getStoreLanguages} from "../../../rise/hyperlive/utils";

if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import gsap from 'gsap';
import { debounce } from 'lodash';
import UniversalLiveTemplate from '../../lib/js/UniversalLiveTemplate';
import Text from './L365-Text'
import Media from './L365-Media'

import './L365.scss'

export default function (datas, demo=false) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const chapterIndex = urlParams.get('chapter') || null;
  const chapterDuration = 8;
  let chapterCount = 0
  const timeline = gsap.timeline({ paused: true, repeat: -1 });
  const textures = [];
  const texturesSizes = [];
  const texturesTypes = [];
  let mediaNumber = 0;

  const texturesMedia = [];

  const shouldPlayChapter = (lang) => {
    return (chapter) => {
      if (chapter.type === 'TEXT' && !chapter.disabled) {
        const oneOfThese = [
          'top_right',
          'bottom_right',
          'heading',
          'content_1',
          'content_2'
        ];

        return oneOfThese.some((key) => {
          return !!chapter?.[key]?.[lang];
        })
      }

      return true;
    }
  }

  let chaptersDuration = [];
    datas.chapters.forEach((ch, index) => {
        let chapterPlaying = !chapterIndex || chapterIndex == 'null' ? true : index == chapterIndex ? true : false
        if(chapterPlaying) {
          ch.index = chapterCount;

          // This forces all chapters to use transition type.
          ch.transition_type = datas.transition_type;

          if (ch.type === 'TEXT' && !ch.disabled) {
            const chapter = new Text(ch, chapterDuration, 'EN', datas.range_finder_transition);
            textures.push(chapter.returnTexture());
            texturesSizes.push(chapter.returnSize());
            texturesTypes.push(chapter.returnType())
            if(ch.index - 1 >= 0) {
              chapter.preTexture(textures[ch.index - 1], texturesTypes[ch.index - 1])
              chapter.setPreTextureSize(texturesSizes[ch.index - 1])
            } else {
              chapter.preTexture('grid', false)
            }
            chaptersDuration.push(8);
            timeline.add(() => {
              chapter.start();

            }, (getPreviusTime(chapterCount)));
            chapterCount++
          } else if (ch.type === 'MEDIA' && !ch.disabled) {
           
            let duration;
            if(ch.media.resource_type == 'image') {
              duration = 8;
            } else {
              duration =  ch.media?.forceDuration &&  ch.media.duration >  ch.media?.forceDuration ?  ch.media?.forceDuration : ch.media.duration;
            }
            const chapter = new Media(ch, duration, 'EN', datas.range_finder_transition, datas.chapters.length);
            textures.push(chapter.returnTexture());
            texturesSizes.push(chapter.returnSize());
            texturesTypes.push(chapter.returnType())
            if(ch.index - 1 >= 0) {
              chapter.preTexture(textures[ch.index - 1],  texturesTypes[ch.index - 1])
              chapter.setPreTextureSize(texturesSizes[ch.index - 1])
            } else {
              chapter.preTexture('grid', false)
            }
            mediaNumber += 1;
            chaptersDuration.push(duration)
            timeline.add(() => {
              chapter.start();
            }, (getPreviusTime(chapterCount)));
            chapterCount++
          }
        }
      });



  function getPreviusTime(index) {
    let sum = chaptersDuration.map((elem, index) => chaptersDuration.slice(0,index + 1).reduce((a, b) => a + b));
    return sum[index - 1] ? sum[index - 1] : 0
  }
  let totalDuration = chaptersDuration.reduce((partialSum, a) => partialSum + a, 0);
  timeline.add(() => {}, (totalDuration));
  timeline.play(getPreviusTime(chapterIndex));

  function resetApps () {
    location.reload();
  }

  window.addEventListener('resize', debounce(resetApps, 500, { trailing: true, leading: false }), false);


  window.addEventListener('keypress', (e) => {
    if (e.code === 'Space') {
      timeline.paused(!timeline.paused());
    }
  });
};
