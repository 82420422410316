import * as PIXI from 'pixi.js-legacy';
import { Grid } from 'rise-grid-framework';
import background from './grids/background';
import { Colors } from './devTools';
import {SIZES} from "./constants";

export default class Background {
	constructor(app, info, zones, thickness, lineColor, size= SIZES.WIDE) {
		this.app = app;
		this.container = new PIXI.Container();

		this.height = info.trackHeight;
		this.size = size;
		// this.width = info.trackLength - 1320;
		this.width = info.trackLength;
		this.renderWidth = info.renderWidth;

		this.grid = new Grid(
			this.container,
			this.width,
			this.height,
			thickness,
			lineColor,
		);

		this.texture = PIXI.RenderTexture.create({
			width: this.width,
			height: this.height,
		});

		const backgroundZones = [];
		let currentLength = 0;
		let start = -1;
		for (let i = 0; i < zones.length; i++) {
			if (zones[i].occlude) {
				backgroundZones.push({
					width: currentLength,
					start: Math.max(start, 0),
					end: zones[i].start,
				});
				currentLength = 0;
				start = zones[i].end;
			} else {
				currentLength += zones[i].end - zones[i].start;
				if (start === -1) start = zones[i].start;
			}
		}

		if (currentLength)
			backgroundZones.push({
				// width: currentLength,
				// @TODO: figure out what this line above means???
				width: currentLength > info.trackLength ? currentLength : info.trackLength,
				start: Math.max(start, 0),
				end: info.trackLength,
			});


		const zonesOffset = backgroundZones.reduce((total, curr, i, arr) => {
			let offset = 0;
			if (i === 0) offset = curr.start;
			else {
				const prev = total.reduce(
					(total, current) => total + current.offset,
					0,
				);
				offset = prev + curr.start - arr[i - 1].end;
			}

			return [...total, { ...curr, offset }];
		}, []);

		const wrapArray = zonesOffset
			.map(({ start, end, width, offset }) => {
				const x = start % this.renderWidth;
				const y = Math.floor(start / this.renderWidth);
				const rows = Math.floor((x + width) / this.renderWidth) || 1;
				const x2 = end % this.renderWidth;
				const y2 = Math.floor(end / this.renderWidth);

				if (x + width > this.renderWidth) {
					return {
						rows: rows + 1,
						x,
						y,
						x2,
						y2,
						width,
						offset,
						start,
						end,
					};
				}

				return { rows, x, y, x2, y2, width, offset, start, end };
			})
			.reduce((total, current, i, arr) => {
				const rows = [];
				const offset = current.offset;
				let pos = current.x;
				for (let i = 0; i < current.rows; i++) {
					if (i === current.rows - 1) {
						rows.push({
							width: current.x2,
							x: 0,
							y: current.y2 * this.height,
							offset,
							end: current.end,
						});
					} else {
						rows.push({
							width: this.renderWidth - pos,
							x: pos,
							y: (current.y + i) * this.height,
							offset,
							end:
								current.start * (i + 1) +
								this.renderWidth -
								pos,
						});
						pos = 0;
					}
				}
				return [...total, ...rows];
			}, []);

		const sprites = wrapArray.map(({ width, x, y, offset, end }, i) => {
			const s = new PIXI.Sprite(this.texture);
			const sMask = new PIXI.Graphics();
			const fillColor = Colors[i % Colors.length];
			sMask
				.beginFill(fillColor)
				.drawRect(x, y, width, this.height)
				.endFill();

			const row = y / this.height;
			const textureX = row * this.renderWidth - offset;

			this.app.stage.addChild(sMask);
			this.app.stage.addChild(s);
			s.mask = sMask;
			s.position.set(-textureX, y);

			return s;
		});
	}

	tick() {
		this.app.renderer.render(this.container, this.texture);
	}

	async run() {
		this.app.ticker.add(this.tick, this);
		await this.grid.draw(background(this.size)[0]);
		this.app.ticker.remove(this.tick, this);
	}
	async rewind() {
		this.app.ticker.add(this.tick, this);
		await this.grid.draw(background(this.size)[1]);
		this.app.ticker.remove(this.tick, this);
	}
}
