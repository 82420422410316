#pragma glslify: box = require('../../../../lib/glsl/box.glsl')
#pragma glslify: mosaic = require('../../../../lib/glsl/mosaic.glsl')
#pragma glslify: viewfinder = require('../../../../lib/glsl/viewfinder.glsl')

#pragma glslify: containUV = require('../../../../lib/glsl/containUV.glsl')
#pragma glslify: pixelateUV = require('../../../../lib/glsl/pixelateUV.glsl')
#pragma glslify: placedUV = require('../../../../lib/glsl/placedUV.glsl')
#pragma glslify: zoomedUV = require('../../../../lib/glsl/zoomedUV.glsl')
#pragma glslify: gammaCorrect = require('../../../../lib/glsl/gammaCorrect.glsl')


#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

// general stuffs
uniform vec2 res; // res
uniform float pixa; // pixelization amount (size of each cell/pixel)
uniform float pixr; // pixel ratio
uniform float stroke; // stroke width

uniform sampler2D img1;
uniform vec2 img1Res;
uniform vec2 img1Pos;
uniform vec2 img1Dim;
uniform vec2 img1Off;
uniform float img1Zoom;
uniform float flip;

vec3 griddedImage (vec2 st, vec3 color, sampler2D img, vec2 imgRes, vec2 pos, vec2 dim, vec2 offset, float zoom, float pr, float px, float flip) {
  imgRes.x *= flip;
  vec2 uv = containUV(vUv, res, imgRes);

  zoom /= (imgRes.y / imgRes.x);
  zoom *= 1.01;

  uv = zoomedUV(uv, imgRes, zoom, offset);
  uv -= offset;
  uv = pixelateUV(uv, px, (imgRes.x / imgRes.y));
  float b = box(gl_FragCoord.xy - pos, dim);
  if ((uv.x > 1. || uv.x < 0.) || (uv.y > 1. || uv.y < 0.)) {
    b = 0.0;
  }
  color = mix(color, texture2D(img, uv, -10.).rgb, b);
  return color;
}

void main() {
  vec3 color = vec3(1.);

  vec2 st = (gl_FragCoord.xy / res) / pixr;
  float ar = (res.x / res.y);
  vec2 var = vec2(1., ar);
  float px = ((res.x * pixr) / pixa);

  vec2 imgRes = (img1Res * pixr);
  vec2 imgPos = (img1Pos * pixr);
  vec2 imgDim = (img1Dim * pixr);

  color = griddedImage(st, color, img1, imgRes, imgPos, imgDim, img1Off, img1Zoom, pixr, px, flip);

  gl_FragColor = vec4(gammaCorrect(color.rgb, 2.2), 1);
}
