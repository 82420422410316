import * as Logger from '../utils/console';
import { transformImage } from '../utils/image';
import {forceHttps} from "../../../utilities";
import {get} from "lodash";
const _ = require('lodash');

const supported_languages = ['EN', 'KO', 'CN'];

export const ChapterTypes = {
	Story: 'story',
	Intro: 'intro',
	Cover: 'cover',
};

export const mapAssets = (assets, mapping = []) => {
	console.log('MAP ASSETS', assets)
	let mappedAssets = [];
	mapping.map(assetMap => {
		console.log(assetMap.tag);
		console.log(assetMap);

		if (assets.hasOwnProperty(assetMap.tag)) {
			assets[assetMap.tag].map((asset, index) => {
				console.log(asset);
				mappedAssets.push({
					name: `${assetMap.prefix}${index + 1}`,
					url: asset.hasOwnProperty('secure_url') ? asset.secure_url : asset.url,
				});
			});
		}
	});

	return mappedAssets;
};

export const mapTransformedAssets = (assets, mapping = [], transform) => {
	console.log('MAP ASSETS', assets)
	let mappedAssets = [];
	mapping.map(assetMap => {
		console.log(assetMap.tag);
		console.log(assetMap);

		if (assets?.hasOwnProperty(assetMap.tag)) {
			assets[assetMap.tag].map((asset, index) => {
				if (!asset) {
					return;
				}
				console.log(asset);
				mappedAssets.push({
					name: `${assetMap.prefix}${index + 1}`,
					url: transformImage(asset.hasOwnProperty('secure_url') ? asset.secure_url : forceHttps(asset.url), transform),
				});
			});
		}
	});

	return mappedAssets;
};

export const remapAssets = (media, chapterType, index) => {
	// Logger.alert('& MEDIA')
	// Logger.log(media, chapterType, index);

	let remapped = [];

	let assetIndex = 0;

	for (const [key, mediaitem] of Object.entries(media)) {
		for (const item of mediaitem) {
			remapped.push({
				name: `${chapterType}.${index}.${key}.${assetIndex}`,
				url: item.secure_url || item.url,
			});

			assetIndex++;
		}
	}

	return remapped;
};

export const fetchJSON = (index = 0) => {
	return _.cloneDeep(window.animations[index]);
};

export const mergeChapters = () => {
	if (!window.channel?.channels) {
		return fetchJSON();
	}

	const params = window.channel?.channels?.[0]?.json_data?.params;
	let sortKey = params?.schedule_ordering_property;
	const direction = params?.schedule_ordering_direction;

	if (sortKey === 'reorderable') {
		sortKey = 'json_data.sort';
	}

	const initialSort = window.channel.channels?.[0]?.schedule?.sort((a, b) => {
		const propA = String(get(a, sortKey, a?.id));
		const propB = String(get(b, sortKey, b?.id));
		return propA.localeCompare(propB, undefined, {numeric: true});
	});

	const sorted = direction === 'reverse' ? initialSort.reverse() : initialSort;

	return sorted.map(i => i.animation).reduce((acc, curr, i) => {
		const {json_data, ...rest} = acc ?? curr;
		const {chapters = []} = json_data;

		let extra = [];
		if (i > 0) {
			extra = curr?.json_data?.chapters ?? []
		}

		return {
			...rest,
			json_data: {
				chapters: [
					...chapters,
					...extra,
				]
			}
		}
	}, null);
}

export const getStoreLanguages = () => {
	const lang = _.cloneDeep(_.get(window, 'languages', []));
	return lang.map(l => l.toUpperCase())
}

export const parseChapterData = (chapters, id) => {
	// Logger.highlight('* Hyperlive')
	// Logger.log('* FETCH CHAPTER', id)
	const chapter = chapters.find(element => element.id === id);
	return chapter;
};

export const fetchAnimations = () => {
	return _.cloneDeep(window.animations);
};

export const fetchChannelInfo = () => {
	return _.cloneDeep(window?.channel?.channels?.[0])
}

export const getChannelInfo = (path, defaultValue) => {
	return _.get(_.cloneDeep(window?.channel?.channels?.[0]), path, defaultValue);
}

export const fetchAssets = (assetId = undefined) => {
	let json = fetchJSON();

	if (!json) {
		return null;
	}

	const { assets } = json.json_data;

	if (!assets) {
		return null;
	}

	if (assetId) {
		return assets[assetId];
	} else {
		return assets;
	}
};

export const getLang = id => {
	// Logger.alert(`#getLang ${id}, ${supported_languages[id]}`)
	return supported_languages[id];
};
