import React, {ReactNode, useEffect} from "react";
import {useEcosystemBoardStore} from "../animations/ecosystem-board/store";

interface ChapterRendererProps {
    chapterTypes: Record<string, any>;
    activeChapter: number;
}

export default function ChapterRenderer({chapterTypes, activeChapter}: ChapterRendererProps) {
    const chapter = useEcosystemBoardStore(s => s.getChapter(activeChapter));

    if (!chapter) {
        return null;
    }

    const Render: React.ElementType = chapterTypes[chapter.template];

    if (!Render) {
        return null;
    }

    // @ts-ignore
    return (
        <Render
            index={activeChapter}
            duration={chapter.duration}
            chapter={chapter}
        />
    );
}