import React, {useMemo} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {NIKE_EASE} from '../../constants/nikerise';
import Codification from '../../components/Codification';
import '../../styles/main.scss';
import './styles/fastlane.scss';
import {constructPulseDataStory, getPulseDataStory} from '../../utils/pulseData';
import _ from 'lodash';
import appNRCIcon from '../../assets/nrc.png';
import appNTCIcon from '../../assets/ntc.png';
import nikeAppIcon from '../../assets/nikeapp.png';
import snkrsIcon from '../../assets/snkrs.png';
import {getStoreLanguages} from '../../hyperlive/utils';
import {MODES, SIZES} from "./constants";
import {containsChinese, containsKorean} from "../../../../utilities";
import Circle from './circle';
import CircleContainer from "./circle-container";

const baseTypographies = (size) => ([
  {
    primary: false,
    typeStyle: {fontSize: size === SIZES.WIDE ? '18px' : '14px', lineHeight: size === SIZES.WIDE ? '22px' : '14px'},
    tag: 'span',
  },
  {
    primary: true,
    typeStyle: {fontSize: '60px', lineHeight: '60px'},
    tag: 'span',
  },
  {
    primary: false,
    typeStyle: {fontSize: '18px', lineHeight: '22px'},
    tag: 'span',
  },
  {
    primary: true,
    typeStyle: {fontSize: '42px', lineHeight: '42px'},
    tag: 'span',
  },
  {
    primary: true,
    typeStyle: {fontSize: '32px', lineHeight: '42px'},
    tag: 'span',
  },
  {
    primary: true,
    typeStyle: {fontSize: '28px', lineHeight: '32px'},
    tag: 'span',
  },
]);

const typeScale = (name, type_index, size, overrideLineHeight = false, multiplier = 1) => {
  let rescaled_type = _.cloneDeep(baseTypographies(size)[type_index]);
  const max_char = 25;
  const char_length = name.length;

  if (size === SIZES.WIDE) {
    if (char_length > max_char) {
      let scale = char_length / max_char;
      rescaled_type.typeStyle.fontSize = `${parseInt(
        rescaled_type.typeStyle.fontSize,
      ) / scale}px`;
      rescaled_type.typeStyle.lineHeight = `${parseInt(
        rescaled_type.typeStyle.lineHeight,
      ) / scale}px`;
    }
  }
  if (overrideLineHeight) {
    rescaled_type.typeStyle.lineHeight = '0.85em';
  }

  if (containsKorean(name)) {
    rescaled_type.typeStyle.lineHeight = `1em`;
    rescaled_type.typeStyle.fontSize = `${parseInt(rescaled_type.typeStyle.fontSize) * .8}px`
  }

  if (containsChinese(name)) {
    rescaled_type.typeStyle.lineHeight = `1em`;
    rescaled_type.typeStyle.fontSize = `${parseInt(rescaled_type.typeStyle.fontSize) * 1.1}px`
  }

  // override with config multiplier
  rescaled_type.typeStyle.fontSize = `${parseInt(rescaled_type.typeStyle.fontSize) * multiplier}px`

  return rescaled_type;
};

const getTypographyStyle = (content, lang, persistentTitle, size) => {
  const typographies = baseTypographies(size)
  let short = 80;
  let long = 120;

  if (persistentTitle) {
    short = 55;
    long = 80;
  }

  if (!content || content.length <= short) return lang === "KO" ? typographies[4] : typographies[3];

  if (content.length > long) {
    return lang === "KO" ? typographies[0] : typographies[5];
  }

  if (content.length > short) {
    return lang === "KO" ? typographies[5] : typographies[4];
  }
}

const getKeyData = (key, data) => {
  const [parentKey, ...rest] = key.split('.');
  return rest.length === 0
    ? data?.[key]
    : getKeyData(rest.join('.'), data[parentKey]);
};

const getUsedLanguages = data => {
  const contentsKeys = [
    'shoe_name',
    'supertitle',
    'subcopy',
    'static_pulse_story',
    'pulse_data_story.content',
  ];

  return getStoreLanguages().filter(language =>
    contentsKeys.some(key => {
      const keyData = getKeyData(key, data);

      if (!keyData) {
        return false;
      }

      const usedLanguages = Object.entries(keyData).reduce(
        (acc, [lang, content]) => (content !== '' ? [...acc, lang] : acc),
        [],
      );

      return usedLanguages.includes(language);
    }),
  );
};

const Text = ({
                lane,
                runner,
                wrap,
                json,
                circleGap,
                persistentTitle = false,
                size = SIZES.WIDE,
                qrWidth = 55,
                fontSizeMultiplier
              }) => {
  persistentTitle = persistentTitle === false ? size === SIZES.COMPACT : true;
  const [frameIndex, setFrameIndex] = React.useState(0);
  const [textFrame, setTextFrame] = React.useState(0);
  const [languageIndex, setLanguageIndex] = React.useState(0);
  const [loopsAmount, setLoopsAmount] = React.useState(0);
  const [showBottom, setShowBottom] = React.useState(false);

  const urlParams = React.useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);

  // use the url params to get a boolean property `swapDesignFor` from a useMemo
  const {swapDesignFor} = React.useMemo(() => {
    const beta = urlParams.get('beta') === 'true';

    return {
      swapDesignFor: beta,
    }
  }, [urlParams]);

  const languages = getUsedLanguages(json.json_data);
  const typographies = baseTypographies(size);
  const height = lane.height;
  const isShort = height === 225;
  const top = wrap ? lane.y + height : lane.y;
  let left = wrap ? lane.startTextX - lane.x + lane.wrapX : lane.startTextX;
  if (size === SIZES.COMPACT) {
    left = left - 280;
  }
  const width = wrap
    ? lane.textWidth
    : lane.textWidth + left > lane.screenWidth
      ? lane.screenWidth - left
      : lane.textWidth;

  React.useEffect(() => {
    runner.setCallback(vals => {
      if (vals.start) setFrameIndex(vals.frameIndex + 1);

      setLoopsAmount(vals.loop);
    });
  }, []);

  React.useEffect(() => {
    const newLanguageIndex = loopsAmount % languages.length;
    if (newLanguageIndex !== languageIndex) setLanguageIndex(newLanguageIndex);

    setTextFrame(0);
    setShowBottom(false);
  }, [loopsAmount]);

  const info = {
    lang: languages[languageIndex],
    name: json.name,
    json_data: json.json_data,
  };

  React.useEffect(() => {
    if (frameIndex === 1 && textFrame === 0) {
      setTimeout(() => {
        setTextFrame(1);
      }, 0); // Show first text frame / animate in
    }
    if (frameIndex === 2 && textFrame === 1) {
      setTimeout(() => setShowBottom(true), 1000);

      setTimeout(() => {
        setTextFrame(2); // Show second text frame
      }, 750);
    }
    if (frameIndex === 3 && textFrame === 2) {
      setTextFrame(3);
    }

    if (frameIndex === 4 && textFrame === 3) {
      setTimeout(() => {
        setTextFrame(4);
      }, persistentTitle ? 2500 : 0);
    }
  }, [setTextFrame, textFrame, frameIndex]);

  const {lang, name, json_data} = info;

  const {
    shoe_name = {},
    pulse_data,
    pulse_data_story,
    static_pulse_story,
    fallback_pulse_data = {},
  } = _.cloneDeep(json_data);

  let static_pulse = static_pulse_story ? static_pulse_story[lang] : '';

  const pulseDataStory = constructPulseDataStory(
    pulse_data_story,
    lang,
  );

  // try and get the pulse data story
  // const pulseDataStory = getPulseDataStory(
  // 	pulse_data,
  // 	fallback_pulse_data,
  // 	lang,
  // );

  // if none just show the static
  static_pulse = pulseDataStory ? pulseDataStory : static_pulse;

  let pulse = null;

  const sport_pulse = pulse ? pulse : static_pulse;

  const mode = _.get(json_data, `mode`, size === SIZES.COMPACT ? MODES.SENSATION : null);
  const ShoeName = _.get(json_data, `shoe_name.${lang}`, size === SIZES.COMPACT ? '' : name);
  const DesignedFor = _.get(json_data, `designed_for.${lang}`);
  const AppIcon = _.get(json_data, 'cta.app', 'NRC');
  const CTAText = _.get(json_data, `cta.text.${lang}`, 'Scan to download')
  const AppText = AppIcon === 'NRC' ? 'Nike Run Club' : 'Nike Training Club';

  const appIcon = {
    NRC: appNRCIcon,
    NTC: appNTCIcon,
    SNKRS: snkrsIcon,
    NIKE: nikeAppIcon,
  }[AppIcon];

  const defaultWidth = 1055;
  const widthOverrides = size === SIZES.COMPACT && {
    minWidth: '660px',
    maxWidth: '660px',
  }

  // console.log('textFrame', textFrame)
  // console.log('textFrame', mode)
  const isProduct = mode === MODES.PRODUCT;
  // console.log('isProduct', isProduct);
  const qrResolution = qrWidth < 88 ? qrWidth * 2 : qrWidth;
  const qrUrl = `${info.json_data.qr_url}&width=${qrResolution}`;
  const showQrCode = ![null, undefined, ''].includes(info.json_data?.qr_url?.trim())
  const multiplier = size === SIZES.COMPACT ? 0.1 : 0.2;


  const wide = () => (
    <div
      style={{
        position: 'absolute',
        height,
        width: '975px',
        top: top + (height * 0.02),
        left,
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
      key={loopsAmount}
    >
      <div className="Fastlane-text">
        {
          persistentTitle && (
            <div className="Fastlane-text-chapter fixed">
              <div className="supertitle">
                {
                  json_data?.supertitle?.[lang] &&
                  <Codification
                    text={json_data?.supertitle?.[lang]}
                    typography={typographies[0]}
                    characterSwitchAmount={4}
                    characterNextTrigger={2}
                    characterTimeout={20}
                    start={textFrame >= 1}
                    reverse={textFrame === 4}
                  />
                }
              </div>

              <div className="product-name">
                <Codification
                  text={ShoeName}
                  typography={typeScale(ShoeName, 1, size)}
                  characterSwitchAmount={4}
                  characterNextTrigger={2}
                  characterTimeout={20}
                  start={textFrame >= 1}
                  reverse={textFrame === 4}
                />
              </div>
            </div>
          )
        }
        <motion.div
          initial={{
            translateY: '0%',
          }}
          animate={{
            translateY: showBottom ? '-50%' : '0%',
          }}
          transition={{ease: NIKE_EASE, duration: 1}}
        >
          <div className="Fastlane-text-chapter">
            {
              !persistentTitle ? (
                <>
                  <div className="supertitle">
                    <Codification
                      text={json_data.supertitle?.[lang]}
                      typography={typographies[0]}
                      characterSwitchAmount={4}
                      characterNextTrigger={2}
                      characterTimeout={20}
                      start={textFrame === 1}
                    />
                  </div>

                  <div className="product-name">
                    <Codification
                      text={ShoeName}
                      typography={typeScale(ShoeName, 1, size)}
                      characterSwitchAmount={4}
                      characterNextTrigger={2}
                      characterTimeout={20}
                      start={textFrame === 1}
                    />
                  </div>
                </>
              ) : (
                <div style={{
                  height: (height / 2.4)
                }}>
                </div>
              )
            }

            <motion.div
              className="subcopy"
              initial={{
                opacity: 1,
              }}
              animate={{
                opacity: persistentTitle ? showBottom ? 0 : 1 : 1,
              }}
            >
              <Codification
                text={json_data.subcopy?.[lang]}
                typography={typographies[2]}
                characterSwitchAmount={4}
                characterNextTrigger={2}
                characterTimeout={20}
                start={textFrame === 1}
              />
            </motion.div>
          </div>

          <div className="Fastlane-text-chapter">
            {
              persistentTitle && (
                <div style={{
                  height: (height / 2.3)
                }}>

                </div>
              )
            }
            <div
              className={'sport-pulse'}
              style={{
                height: persistentTitle ? 0 : undefined,
                overflow: 'visible',
              }}
            >
              <Codification
                text={sport_pulse}
                typography={persistentTitle ? typographies[2] : getTypographyStyle(sport_pulse, lang, persistentTitle, size)}
                characterSwitchAmount={4}
                characterNextTrigger={2}
                characterTimeout={persistentTitle ? 10 : 20}
                start={showBottom}
                reverse={persistentTitle ? textFrame >= 3 : textFrame === 4}
              />
            </div>

            {json_data.cta !== 'None' && (
              <motion.div
                className="App"

                initial={{
                  opacity: persistentTitle ? 0 : 1,
                  y: 0,
                  marginTop: persistentTitle ? '0' : '20px'
                }}
                animate={{
                  opacity: persistentTitle ? (
                    textFrame >= 3 ? 1 : 0
                  ) : 1,
                  y: persistentTitle ? (
                    textFrame >= 3 ? '-20px' : 0
                  ) : 0,
                }}
                transition={{
                  delay: 1.5,
                  duration: .8
                }}
              >
                <div
                  className="App-icon"
                  style={{width: persistentTitle ? '85px' : '60px'}}>
                  <AnimatePresence>
                    {
                      showBottom && textFrame < 4 && (
                        <motion.div
                          key={'icons'}
                          style={{
                            position: 'absolute',
                          }}
                          initial={{
                            opacity: 0,
                          }}
                          animate={{
                            opacity: 1,
                            transition: {
                              delay: 0.25,
                              duration: 1,
                            },
                          }}
                          exit={{opacity: 0}}
                        >
                          <img
                            src={appIcon} alt=""
                            style={{
                              width: persistentTitle ? '74px' : '50px',
                              height: persistentTitle ? '74px' : '50px'
                            }}/>
                          <img
                            style={{
                              borderRadius: '15%',
                              width: persistentTitle ? '74px' : '50px',
                              height: persistentTitle ? '74px' : '50px',
                            }}
                            src={info.json_data.qr_url}
                          />
                        </motion.div>
                      )
                    }
                  </AnimatePresence>
                </div>
                <div className="subcopy"
                     style={{
                       paddingTop: persistentTitle ? '24px' : '17px',
                       paddingLeft: persistentTitle ? '84px' : '72px',
                     }}>
                  <AnimatePresence>
                    {
                      showBottom && textFrame < 4 && (
                        <motion.div
                          key={`code-${loopsAmount}`}
                          initial={{
                            opacity: 0,
                          }}
                          animate={{
                            opacity: 1,
                            transition: {
                              delay: 0.25,
                              duration: 1,
                            },
                          }}
                          exit={{opacity: 0}}
                        >
                          <Codification
                            text={CTAText}
                            typography={typographies[0]}
                            characterSwitchAmount={4}
                            characterNextTrigger={2}
                            characterTimeout={20}
                            delay={AppText.length * 60}
                            start={showBottom && textFrame === 2}
                            reverse={textFrame === 3}
                          />
                        </motion.div>
                      )
                    }
                  </AnimatePresence>
                </div>
              </motion.div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );

  const compact = ({renderCircle, left, index, textOffset = 0}) => (
    <div
      style={{
        position: 'absolute',
        height: height + 2,
        width: `${defaultWidth}px`,
        // top: top + (height * 0.01),
        // top: top + (height * -0.01),
        top: top - 2,
        left: left + Number(textOffset),
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
      key={`${loopsAmount}-${index}`}
    >
        {
          renderCircle && (
            <CircleContainer
              textOffset={textOffset}
              count={json_data?.shoe_count}
              height={height + 2}
              gap={circleGap}
            />
          )
        }

      <div className="Fastlane-text COMPACT">
        <div className="Fastlane-text-chapter fixed">
          <div className="supertitle" style={widthOverrides}>
            {
              json_data?.supertitle?.[lang] &&
              <Codification
                text={json_data?.supertitle?.[lang]}
                typography={{...typographies[0], lineHeight: `.85em`}}
                characterSwitchAmount={4}
                characterNextTrigger={2}
                characterTimeout={20}
                setLanguageClassName={true}
                start={textFrame >= 1}
                reverse={textFrame === 4}
              />
            }
          </div>

          {
            swapDesignFor && (
              <div>
                <Codification
                  text={DesignedFor || (isProduct ? ShoeName : json_data.subcopy?.[lang]?.replace('\n', ''))}
                  typography={typographies[0]}
                  characterSwitchAmount={4}
                  characterTimeout={10}
                  characterNextTrigger={2}
                  setLanguageClassName={true}
                  start={textFrame >= 1}
                  reverse={textFrame === 4}
                />
              </div>
            )
          }

          <div className="supertitle" style={widthOverrides}>
            <div style={{}}>
              <Codification
                text={isProduct ? name : ShoeName}
                respectWhitespace
                className={"supertitle-height"}
                typography={{
                  ...typeScale(isProduct ? name : ShoeName, 1, undefined, true, fontSizeMultiplier),
                  tag: 'div'
                }}
                characterSwitchAmount={4}
                characterNextTrigger={2}
                characterTimeout={20}
                setLanguageClassName={true}
                start={textFrame >= 1}
                tag={'div'}
                reverse={isProduct ? textFrame === 4 : textFrame >= 2}
              />
            </div>

            <div style={{position: 'absolute', top: '0px', overflow: 'visible'}}>
              {
                !isProduct && (
                  <Codification
                    className={"productname"}
                    text={json_data.subcopy?.[lang]}
                    typography={typeScale(json_data.subcopy?.[lang] ?? ShoeName, 1, undefined, true, fontSizeMultiplier)}
                    characterSwitchAmount={4}
                    characterNextTrigger={2}
                    characterTimeout={20}
                    setLanguageClassName={true}
                    start={textFrame >= 2}
                    delay={700}
                    reverse={textFrame === 4}
                  />
                )
              }

            </div>

            <div
              className="subcopy"
              style={{
                position: 'relative',
                marginTop: fontSizeMultiplier < 0.93 ? '-4px' : '0px',
              }}
            >
              {
                !swapDesignFor && (
                  <div>
                    <Codification
                      text={DesignedFor || (isProduct ? ShoeName : json_data.subcopy?.[lang]?.replace('\n', ''))}
                      typography={typographies[2]}
                      characterSwitchAmount={4}
                      characterTimeout={10}
                      characterNextTrigger={2}
                      setLanguageClassName={true}
                      start={textFrame >= 1}
                      reverse={isProduct ? textFrame === 4 : textFrame >= 2}
                    />
                  </div>
                )
              }


              <div style={{position: "absolute", top: 0}}>
                {
                  !isProduct && (swapDesignFor === false) && (
                    <Codification
                      text={DesignedFor || name?.replace('\n', '')}
                      typography={typographies[2]}
                      characterSwitchAmount={4}
                      characterNextTrigger={2}
                      characterTimeout={10}
                      start={textFrame >= 2}
                      setLanguageClassName={true}
                      delay={700}
                      reverse={textFrame === 4}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="App-Icons">
            {json_data.cta !== 'None' && (
              <motion.div
                className="App"
                initial={{
                  opacity: 0,
                  marginTop: 0
                }}
                animate={{
                  opacity: (textFrame >= 1 && textFrame <= 3) ? 1 : 0,
                }}
                style={{
                  y: isShort ? -20 : 0,
                }}
                transition={{
                  delay: 1.5,
                  duration: .8
                }}
              >
                <motion.div
                  key={'icons'}
                  style={{
                    // position: 'absolute',
                  }}
                  initial={{
                    opacity: 0,
                  }}
                  className="App-icon"
                  animate={{
                    opacity: 1,
                    transition: {
                      delay: 0.25,
                      duration: 1,
                    },
                  }}
                  exit={{opacity: 0}}
                >
                  <img
                    style={{
                      borderRadius: '15%',
                      width: `${qrWidth}px`,
                      height: `${qrWidth}px`,
                      display: !showQrCode && 'none',
                    }}
                    src={qrUrl}
                  />
                  <img
                    src={appIcon} alt=""
                    style={{
                      width: `${qrWidth}px`,
                      height: `${qrWidth}px`,
                    }}
                  />
                </motion.div>
                <div
                  className="App-Cta"
                >
                  <motion.div
                    key={`code-${loopsAmount}`}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        delay: 0.25,
                        duration: .5,
                      },
                    }}
                    exit={{opacity: 0}}
                  >
                    <Codification
                      text={CTAText}
                      typography={typographies[0]}
                      characterSwitchAmount={4}
                      characterNextTrigger={2}
                      characterTimeout={20}
                      delay={1000}
                      start={textFrame === 1}
                      reverse={textFrame === 3}
                    />
                  </motion.div>
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const offsetLeft = height === 225 ? 310 : 295;
  const circleWidth = (parseInt(circleGap) || 0) + 225;

  const render = size === SIZES.WIDE ? wide() : (
    <>
      {compact({renderCircle: true, left: left, textOffset: json_data?.text_offset, index: 0})}
      {/*{*/}
      {/*  Number(json_data?.shoe_count) > 1 &&*/}
      {/*  compact({*/}
      {/*    renderCircle: false,*/}
      {/*    index: 1,*/}
      {/*    textOffset: json_data?.text_offset_2,*/}
      {/*    left:*/}
      {/*      left + offsetLeft + 30 + (circleWidth * Number(json_data?.shoe_count))*/}
      {/*  })*/}
      {/*}*/}
    </>
  );

  return frameIndex > 0 ? render : null;
};

export default Text;
