import { useEffect } from 'react';
import { VideoWallProps } from './type';
import * as THREE from 'three';
import { useVideoTexture } from '@react-three/drei';

function VideoMaterial({ texture }) {
    return <meshBasicMaterial map={texture} toneMapped={true} />
}

export const VideoWall = ({ layout, url, onVideoEnded, loop, muted }: VideoWallProps) => {
    const texture = useVideoTexture(url, {loop, muted});
    const videoMaterial = <VideoMaterial texture={texture} />;
    texture.needsUpdate = true;
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.offset.x = 0;
    texture.offset.y = 0;
    texture.repeat.set(layout.width/layout.canvas_width,1);
    texture.name = url;
    texture.encoding = THREE.sRGBEncoding;
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;

    const texture1 = texture.clone();
    texture1.needsUpdate = true;
    texture1.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture1.offset.x = layout.width/layout.canvas_width;
    texture1.offset.y = 0;
    texture1.repeat.set((layout.canvas_width - layout.width)/ layout.canvas_width,1);
    texture1.name = url;
    texture1.encoding = THREE.sRGBEncoding;
    texture1.minFilter = THREE.LinearFilter;
    texture1.magFilter = THREE.LinearFilter;
    const videoMaterial1 = <VideoMaterial texture={texture1} />;

    useEffect(() => {
        texture.image.onended = () => {
            if(!loop) onVideoEnded();
        };   
    }, [loop, onVideoEnded, texture.image]);

    return (
        <group>
            <mesh position={[0,layout.rows === 1 ? 0 : layout.height/2, 0]}>
                <planeGeometry args={[layout.width, layout.height]} />
                {videoMaterial}
            </mesh>
            {layout.rows > 1 && <mesh position={[-layout.width/2 + (layout.canvas_width - layout.width)/2, -layout.height/2, 0]}>
                <planeGeometry args={[layout.canvas_width - layout.width, layout.height]} />
                {videoMaterial1}
            </mesh>}
        </group>
    );
};
