import * as THREE from 'three';
import * as localForage from "localforage";
import md5 from 'md5';

localForage.config({
  driver: localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: 'EnjoyImages',
  version: 1.0,
  size: 5 * 1024 * 1024, // Size of database, in bytes. WebSQL-only for now.
  storeName: 'EnjoyImages', // Should be alphanumeric, with underscores.
  description: 'Enjoy Image Storage'
});

export default class WebGLTemplateLayer {
  constructor(canvas, renderer, { setupUniforms, setupTextures }, { vert, frag }, name) {
    this.name = name;
    this.noResUpdate = false;
    this.textures = {};
    this.storage = localForage.createInstance({
      name: "EnjoyImages"
    });

    if (typeof this.preInit === 'function') {
      this.preInit();
    }

    this.canvas = canvas;

    this.setupRenderer(renderer);

    if (typeof setupUniforms === 'function') {
      this.uniforms = setupUniforms();
    }
    if (typeof setupTextures === 'function') {
      setupTextures(this.createTexture.bind(this));
    }

    this.setupShader(vert, frag);

    this.setupQuad();

    window.addEventListener('resize', this.onWindowResize.bind(this), false);

    this.running = false;
  }

  setupRenderer(renderer) {
    this.renderer = renderer;

    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1, 10);

    this.scene = new THREE.Scene();
    this.scene.background = null;

    this.clock = new THREE.Clock();
  }

  setupShader(vert, frag) {
    const screen = new THREE.Vector2(
      this.canvas.width,
      this.canvas.height
    );
    this.uniforms.res = new THREE.Uniform(screen);

    this.shader = new THREE.ShaderMaterial({
      transparent: true,
      uniforms: this.uniforms,
      vertexShader: vert,
      fragmentShader: frag,
    });
  }

  setupQuad() {
    this.quad = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), this.shader);
    this.quad.position.set(0, 0, -2);
    this.scene.add(this.quad);
  }


  createTexture({ src, tex, key, pos, dim, offset, pixel, zoom, zoomRF, opacity, forceJPG = false }, pristine = true) {
    let texture = tex;
    if (src) {
      src = forceJPG ? src?.replace('.png', '.jpg') : src;
      const hash = md5(src);
      if (this.textures[hash]) {
        texture = this.textures[hash];
        this.uniforms[key].value = texture;
        this.uniforms[key + 'Res'].value.x = texture.image.width;
        this.uniforms[key + 'Res'].value.y = texture.image.height;
        texture.encoding = THREE.sRGBEncoding;
        texture.needsUpdate = true;
      } else {
        const img = new Image();
        texture = new THREE.Texture(img);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.encoding = THREE.sRGBEncoding;
        texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        img.crossOrigin = 'anonymous';
        img.onload = () => {
          this.uniforms[key].value = texture;
          this.uniforms[key + 'Res'].value.x = texture.image.width;
          this.uniforms[key + 'Res'].value.y = texture.image.height;
          texture.needsUpdate = true;
          this.textures[hash] = texture;
        }
        localForage.getItem(hash.toString(), (err, blob) => {
          // if err is non-null, we got an error. otherwise, value is the value
          if (!err) {
            if (blob) {
              const urlBlob = URL.createObjectURL(blob)
              img.src = urlBlob;
            } else {
              let xhr = new XMLHttpRequest();
              xhr.open("GET", src, true);
              xhr.responseType = "blob";
              xhr.addEventListener("load", () => {
                if (xhr.status === 200) {
                  const blob = xhr.response;
                  const urlBlob = URL.createObjectURL(blob);
                  img.src = urlBlob;

                  localForage.setItem(hash.toString(), xhr.response)
                    .then(() => {
                      console.log(`blob Hash ${hash} saved in IndexedDB`);
                    });
                }
              }, false);
              // Send XHR
              xhr.send();
            }
          } else {
            console.log(err);
          }
        });
      }
    } else if (!pristine) {
      this.uniforms[key].value = texture;
      this.uniforms[key + 'Res'].value.x = texture.image.width;
      this.uniforms[key + 'Res'].value.y = texture.image.height;
    }
    if (pristine && texture) {
      this.setPristine(texture, pos, dim, offset, key, pixel, zoom, zoomRF, opacity)
    }
  }

  setPristine(texture, pos, dim, offset, key, pixel, zoom, zoomRF, opacity) {
    this.uniforms[key] = new THREE.Uniform(texture);
    this.uniforms[key + 'Res'] = new THREE.Uniform(new THREE.Vector2(
      texture.image.width,
      texture.image.height
    ));
    if (pos !== undefined) this.uniforms[key + 'Pos'] = new THREE.Uniform(pos);
    if (dim !== undefined) this.uniforms[key + 'Dim'] = new THREE.Uniform(dim);
    if (offset !== undefined) this.uniforms[key + 'Off'] = new THREE.Uniform(offset);
    if (pixel !== undefined) this.uniforms[key + 'Pix'] = new THREE.Uniform(pixel);
    if (zoom !== undefined) this.uniforms[key + 'Zoom'] = new THREE.Uniform(zoom);
    if (zoomRF !== undefined) this.uniforms[key + 'ZoomRF'] = new THREE.Uniform(zoomRF);
    if (opacity !== undefined) this.uniforms[key + 'Opacity'] = new THREE.Uniform(opacity);
  }

  render() {
    if (this.running) {
      const dt = this.clock.getDelta();
      this.uniforms.time.value += dt;

      if (typeof this.update === 'function') {
        this.update(this.uniforms.time.value);
      }
    }

    this.renderer.render(this.scene, this.camera);

    this.raf = requestAnimationFrame(this.render.bind(this));
  }

  onWindowResize() {
    if (this.running) {
      this.camera.updateProjectionMatrix();
      if (!this.noResUpdate) {
        this.uniforms.res.value.x = this.canvas.clientWidth;
        this.uniforms.res.value.y = this.canvas.clientHeight;
      }
    }
  }

  start(keyframe) {
    if (typeof this.preStart === 'function') this.preStart();
    this.onWindowResize();
    window.cancelAnimationFrame(this.raf);
    this.raf = requestAnimationFrame(this.render.bind(this));
    this.running = true;
  }

  resume() {
    if (typeof this.preResume === 'function') this.preResume();
    this.running = true;
    this.clock.start();
  }

  pause() {
    if (typeof this.prePause === 'function') this.prePause();
    this.running = false;
    this.clock.stop();
  }

  stop() {
    if (typeof this.preStop === 'function') this.preStop();
    window.cancelAnimationFrame(this.raf);
    this.running = false;
  }
}
